import { combineReducers } from '@reduxjs/toolkit';

import {
  accountSlice,
  couponsSlice,
  loyaltyProgramSlice,
  navigationSlice,
  rewardsSlice,
  toastSlice,
  tiersProgramSlice,
  appInstallStatusSlice,
  localeSlice,
} from './slices';

export const rootReducer = combineReducers({
  couponsConfig: couponsSlice.reducer,
  accountConfig: accountSlice.reducer,
  rewardsConfig: rewardsSlice.reducer,
  navigationConfig: navigationSlice.reducer,
  loyaltyProgramConfig: loyaltyProgramSlice.reducer,
  toastConfig: toastSlice.reducer,
  tiersProgramConfig: tiersProgramSlice.reducer,
  appInstallStatusConfig: appInstallStatusSlice.reducer,
  localeConfig: localeSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
