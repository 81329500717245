import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loyaltyUouClick } from '@wix/bi-logger-loyalty-uou/v2';

import { ThunkApiConfig } from '../../../../../../types/store';
import { LOYALTY_APP_DEFINITION_ID, LOYALTY_APP_SECTION_ID } from '../../../../../../constants';

const navigateToLoyaltyPage = createAsyncThunk<void, void, ThunkApiConfig>(
  'navigation/navigateToLoyaltyPage',
  async (_, { extra, rejectWithValue }) => {
    const { flowAPI, wixCodeApi } = extra;
    try {
      if (flowAPI.environment.isViewer) {
        flowAPI.bi?.report(
          loyaltyUouClick({
            value: 'learn_how_to_earn_points',
          }),
        );
      }
      const url = await wixCodeApi.site.getSectionUrl({
        appDefinitionId: LOYALTY_APP_DEFINITION_ID,
        sectionId: LOYALTY_APP_SECTION_ID,
      });
      if (url.relativeUrl) {
        wixCodeApi.location.to?.(url.relativeUrl);
      }
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(navigateToLoyaltyPage.fulfilled, () => {}).addCase(navigateToLoyaltyPage.rejected, () => {});
  },
});

export const navigationThunk = { navigateToLoyaltyPage };
