import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentMemberAccount } from '@wix/ambassador-loyalty-v1-account/http';

import { RequestStatus, ThunkApiConfig } from '../../../../../../types/store';
import { SimpleLoyaltyAccount } from '../../../../../../types/domain';
import { toSimpleAccount } from '../../../../../../utils';

export interface AccountConfigProps {
  account: SimpleLoyaltyAccount;
  fetchAccountStatus: RequestStatus;
}

const initialState: AccountConfigProps = {
  account: {},
  fetchAccountStatus: RequestStatus.IDLE,
};

const fetchAccount = createAsyncThunk<SimpleLoyaltyAccount | undefined, void, ThunkApiConfig>(
  'account/fetchAccount',
  async (_, { extra, rejectWithValue }) => {
    try {
      const { data } = await extra.flowAPI.httpClient.request(getCurrentMemberAccount({}));
      if (data.account) {
        return toSimpleAccount(data.account);
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetFetchAccountStatus: (state) => {
      state.fetchAccountStatus = RequestStatus.IDLE;
    },
    deductPoints: (state, action: PayloadAction<number>) => {
      if (state.account.pointsBalance) {
        state.account.pointsBalance -= action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccount.fulfilled, (state, action) => {
        if (action.payload) {
          state.account = action.payload;
        }
        state.fetchAccountStatus = RequestStatus.SUCCESS;
      })
      .addCase(fetchAccount.pending, (state) => {
        state.fetchAccountStatus = RequestStatus.LOADING;
      })
      .addCase(fetchAccount.rejected, (state) => {
        state.fetchAccountStatus = RequestStatus.FAILURE;
      });
  },
});

export const accountThunk = { fetchAccount };
