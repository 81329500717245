import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LOCALE } from '../../../../../../constants';

export interface LocaleProps {
  locale: string;
}

const initialState: LocaleProps = {
  locale: DEFAULT_LOCALE,
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {},
});
