import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  ProgramStatus,
  LoyaltyProgram,
  Status as PointsExpirationStatus,
} from '@wix/ambassador-loyalty-v1-program/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyCoupon, Status, CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { getTiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/http';
import {
  CouponStatusStatus as ReferralCouponStatus,
  DiscountType as ReferralsCouponType,
} from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';

import { LoadDataResult } from '../load-data';
import { TiersProgram } from '../../types/domain';
import { AppInstallStatus } from '../getAppInstallStatus';

interface CreateFixturesParams {
  flowAPI: ControllerFlowAPI;
  appInstallStatus: AppInstallStatus;
}

export const getDemoContent = async ({ flowAPI, appInstallStatus }: CreateFixturesParams): Promise<LoadDataResult> => {
  const { httpClient, translations } = flowAPI;
  const { t } = translations;
  const rewards: Reward[] = [];
  const tiersProgram: TiersProgram = {};
  const referralCouponRewards: LoadDataResult['referralCouponRewards'] = [];
  const {
    isLoyaltyAppInstalled,
    isReferralsAppInstalled,
    isStoresAppInstalled,
    isEventsAppInstalled,
    isBookingsAppInstalled,
    isOldRestaurantsAppInstalled,
    isNewRestaurantsAppInstalled,
  } = appInstallStatus;

  const [loyaltyProgramResponse, tiersProgramSettingsResponse] = await Promise.all(
    isLoyaltyAppInstalled
      ? [httpClient.request(getLoyaltyProgram({})), httpClient.request(getTiersProgramSettings({}))]
      : [],
  );

  const pointsExpirationData = loyaltyProgramResponse?.data?.loyaltyProgram?.pointsExpiration;

  const showLoyaltyCouponRewards =
    isLoyaltyAppInstalled && (isStoresAppInstalled || isEventsAppInstalled || isBookingsAppInstalled);
  const showLoyaltyDiscountRewards =
    isLoyaltyAppInstalled &&
    (isOldRestaurantsAppInstalled || isNewRestaurantsAppInstalled || isStoresAppInstalled || isBookingsAppInstalled);
  const showPointsExpiration = isLoyaltyAppInstalled && pointsExpirationData?.status === PointsExpirationStatus.ENABLED;

  const loyaltyProgram: LoyaltyProgram = {
    ...loyaltyProgramResponse?.data.loyaltyProgram,
    status: ProgramStatus.ACTIVE,
  };

  const currentDate = new Date();

  const account: LoyaltyAccount = {
    points: {
      balance: 30,
    },
    tier: {
      points: 60,
    },
    ...(showPointsExpiration && {
      pointsExpiration: {
        expiringPointsAmount: 30,
        expirationDate: new Date(
          currentDate.setMonth(currentDate.getMonth() + (pointsExpirationData.monthsOfInactivity ?? 3)),
        ),
      },
    }),
  };

  if (showLoyaltyDiscountRewards) {
    rewards.push(
      {
        id: '129aff97-f34f-44b7-8aa0-51c4a47668b3',
        name: t('app.fixtures.reward-a.title'),
        active: true,
        discountAmount: {
          configsByTier: [
            {
              costInPoints: 20,
              amount: '15',
            },
          ],
        },
        type: RewardType.DISCOUNT_AMOUNT,
      },
      {
        id: '712fc9d0-6ee6-4da6-a960-690fa617a31f',
        name: t('app.fixtures.reward-b.title'),
        active: true,
        discountAmount: {
          configsByTier: [
            {
              costInPoints: 50,
              amount: '7',
            },
          ],
        },
        type: RewardType.DISCOUNT_AMOUNT,
      },
    );
  }

  let templateCoupons: CouponReference[] = [];
  let claimedCoupons: LoyaltyCoupon[] = [];

  if (showLoyaltyCouponRewards) {
    const templateCouponCId = '0a79af45-c6ad-40b0-995b-76f9247b27a4';
    const templateCouponDId = '00c53f39-2185-4f01-adc7-925574e2ca70';
    const templateCouponEId = '64f840b7-2c4f-49be-8330-1e25cacc75bf';
    const claimedCouponId = '4e78b097-6c33-497f-b404-703e8bacbee5';

    rewards.push(
      {
        id: '5b62a1c8-1906-4a75-bf80-280044446bbb',
        name: t('app.fixtures.reward-d.title'),
        active: true,
        coupon: {
          templateId: templateCouponDId,
          configs: [
            {
              requiredPoints: 20,
            },
          ],
        },
        type: RewardType.COUPON,
        createdDate: new Date(),
      },
      {
        id: '19a42873-02c0-4a26-96ad-eaeeabb85773',
        name: t('app.fixtures.reward-e.title'),
        active: true,
        coupon: {
          templateId: templateCouponEId,
          configs: [
            {
              requiredPoints: 50,
            },
          ],
        },
        type: RewardType.COUPON,
        createdDate: new Date(),
      },
    );

    templateCoupons = [
      {
        couponId: templateCouponCId,
        specification: {
          moneyOffAmount: 3,
        },
      },
      {
        couponId: templateCouponDId,
        specification: {
          moneyOffAmount: 4,
        },
      },
    ];

    claimedCoupons = [
      {
        id: claimedCouponId,
        createdDate: new Date(),
        status: Status.ACTIVE,
        couponReference: {
          code: 'KOW7AF6UVY53',
          specification: {
            moneyOffAmount: 12,
          },
        },
        rewardName: t('app.fixtures.reward-c.title'),
      },
    ];
  }

  if (isReferralsAppInstalled) {
    const createdDate = new Date();
    createdDate.setSeconds(createdDate.getSeconds() + 1);

    referralCouponRewards.push({
      id: '1970fbbb-eb5c-469c-9e2c-2bc791129796',
      createdDate,
      coupon: {
        status: ReferralCouponStatus.ACTIVE,
        code: '6JV5990N3XRN',
        couponSpecification: {
          discountType: ReferralsCouponType.PERCENTAGE,
          name: t('app.fixtures.referral-reward.title'),
          scope: {
            namespace: 'stores',
          },
          percentageOptions: {
            percentage: 10,
          },
        },
      },
    });
  }

  if (tiersProgramSettingsResponse?.data) {
    const tiersStatus = tiersProgramSettingsResponse.data.programSettings?.status;

    tiersProgram.programSettings = {
      status: tiersStatus,
      baseTierDefinition: {
        name: t('app.fixtures.tier-basic.title'),
        icon: {
          url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
        },
      },
    };

    tiersProgram.tiers = [
      {
        id: 'fa4d72b2-096f-4fc7-89c4-d26163a4674a',
        tierDefinition: {
          name: t('app.fixtures.tier-silver.title'),
          icon: {
            url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
          },
        },
        requiredPoints: 100,
      },
    ];
  }

  return {
    loyaltyProgram,
    account,
    rewards,
    templateCoupons,
    claimedCoupons,
    referralCouponRewards,
    couponEntityNames: {},
    tiersProgram,
  };
};
